
.dropdown-container{
    width: 100px;
    height: 30px;
}

.dropdown-content{
    width: 100%;
    height: 100%;
    border: none;
    /* opacity: 0; */
    color: black;
}