.lesson-container{
    width: 60%;
    margin: auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.lesson-detail{
    font-size: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-weight: 300;

    line-height: 30px;

    margin: 10px 0;
}