.activity-container{
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.activity-header{
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.activity-content-title-arrow{
    width: 70px;
    height: 130px;
    margin: auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 48px;
    color: #888;
    cursor: pointer;
    /* border: 1px solid black; */
    border-radius: 25px;
}
.activity-content-title{
    font-size: 48px;
    font-family: Cinzel,serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 8.5px;
    color: #9c7410;
}

.activity-content-list{
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}


/* ===================================================== activityCard */
.activityCard-container{
    margin: 10px auto;
    display: flex;
    background-color: #fff;
    min-width: 900px;
    width: 90%;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 255, 0.2);
}
.activityCard-container:hover{
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 255, 0.5);
}

.activityCard-imageContainer{
    max-height: 700px;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    margin: auto 0;
}
.activityCard-image{
    max-height: 700px;
    max-width: 600px;
    border-radius: 10px;
    cursor: pointer;
    transition:transform 0.5s;
}
.activityCard-image:hover{
    transform:scale(1.1);
}

.activityCard-content{
    margin: auto;
    display: flex;
    flex-direction: column;
    
}

.activityCard-content::-webkit-scrollbar{
    width : 10px; 
}
.activityCard-content::-webkit-scrollbar-thumb{
    border-radius   : 10px;
    background-color: #8b8b8b;
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
}
.activityCard-content::-webkit-scrollbar-track{
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.activityCard-content-detail{
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    margin-top: 20px;
}
.activityCard-content-button{
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40%;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;

    font-size: 16px;
    background-color: #6C7D8D;
    color: #fff;
}
.activityCard-content-button:hover{
    background-color: #35404b;
    color: #fff;
}
.activityCard-content-link{
    display: flex;
    margin: 10px auto;
}

@media(max-width:576px){
    .activityCard-container{
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        min-width: 300px;
        width: 90%;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 1px rgba(0, 0, 255, 0.2);
    }
    .activityCard-imageContainer{
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin: auto 0;
    }
    .activityCard-image{
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        transition:transform 0.5s;
    }
}
/* ------------------------- */
.activityCard-font1{
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}

.activityCard-font2{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.activityCard-content-item{
    margin: 5px;
    cursor: pointer;
}